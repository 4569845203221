.chat-container {
  height: '100%';
  overflow-y: none;
  display: 'flex';
}

.chat-msgs-container {
  height: '100%';
  display: 'flex';
  flex-direction: 'column';
  overflow-y: auto;
  padding: 15px;
}

.user-chat-message {
  height: fit-content;
  display: flex;
  flex-direction: row;
  background-color: whitesmoke;
  color: black;
  padding: 15px;
  font-size: 1rem;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
  margin-right: 2rem;
}

.ai-chat-message {
  height: fit-content;
  display: flex;
  flex-direction: row;
  background-color: rgba(29, 53, 87, 1);
  padding: 15px;
  font-size: 1rem;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
  margin-left: 2rem;
}


@media (min-width: 1000px) {
  .ai-chat-message {
    margin-left: 6rem;
  }

  .user-chat-message {
    margin-right: 6rem;
  }

}

.ai-chat-message-link {
  color: #D4BAEB;
}

.ai-chat-web-link {
  text-transform: capitalize;
  max-width: 30rem;
}