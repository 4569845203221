html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
}

pre {
  overflow-x: auto;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

h1 {
  color: #383258;
  font-weight: bold;
}

h2 {
  color: #383258;
  font-weight: bold;
}


.scrollbar {
  margin-left: 30px;
  float: left;
  height: 300px;
  width: 65px;
  background: #F5F5F5;
  overflow-y: scroll;
  margin-bottom: 25px;
}
