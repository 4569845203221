/*
Copyright 2017-present Palantir Technologies, Inc. All rights reserved.
Licensed under the Apache License, Version 2.0.
*/
@font-face {
  font-family: "blueprint-icons-16";
  src: url("./blueprint-icons-16.ttf?534f7eb275330dacf35068f4d857107a") format("truetype"), url("./blueprint-icons-16.eot?534f7eb275330dacf35068f4d857107a#iefix") format("embedded-opentype"), url("./blueprint-icons-16.woff2?534f7eb275330dacf35068f4d857107a") format("woff2"), url("./blueprint-icons-16.woff?534f7eb275330dacf35068f4d857107a") format("woff");
}
@font-face {
  font-family: "blueprint-icons-20";
  src: url("./blueprint-icons-20.ttf?d1b8427a48d845624f800c9178011592") format("truetype"), url("./blueprint-icons-20.eot?d1b8427a48d845624f800c9178011592#iefix") format("embedded-opentype"), url("./blueprint-icons-20.woff2?d1b8427a48d845624f800c9178011592") format("woff2"), url("./blueprint-icons-20.woff?d1b8427a48d845624f800c9178011592") format("woff");
}

/*# sourceMappingURL=blueprint-icons.css.map */