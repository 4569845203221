.banner {
  display: flex;
  flex-direction: column;
  position: relative;
}

.banner-img-container {
  flex: 0 0 auto;
  position: relative;
  height: 80vh;
  max-height: 800px;
}

.banner-img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: bottom;
}

.banner-items {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  flex: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  text-align: center;
}

.banner-items h1 {
  margin-top: 2rem;
  font-size: 2rem;
}

.banner-items p {
  padding: 1rem;
  padding-top: 0.2rem;
  font-size: 1.2rem;
  max-width: 280px;
  margin: 0 auto;
}

.banner-padding-top {
  flex: 0;
}

.banner-padding-bottom {
  flex: 0.5;
}

.landing-page-content-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.landing-page-content {
  max-width: 600px;
}

.bp4-card {
  border-radius: 7px !important;
}

.card {
  max-width: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.language_tagline {
  padding: 0.5rem;
  font-size: 1rem;
}

.card img {
  width: 80%;
}

.card span {
  margin-top: 12px;
  margin-bottom: 12px;
  font-size: 1.2em;
}

.testimonial {
  margin-top: 0px;
  margin-bottom: 30px;
}
