.img-background {
  background-image: url('../assets/sky.svg');
  background-position: center;
  background-size: cover;
}

.img-bridge {
  background-image: url('../assets/background_centered_bridge.svg');
  background-position: bottom;
  background-size: cover;
}

.wrapper {
  height: 100%;
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

@media (min-width: 2000px) {
  #page-wrapper {
    max-width: 70% !important;
  }
}

@media (min-width: 1000px) {
  #page-footer {
    display: flex !important;
    height: 5rem !important;
  }

  #center-content {
    min-height: calc(100% - 10rem) !important;
    max-width: 75%;
    margin: 0 auto;
  }

  #page-header {
    min-width: 80%;
    margin: 0 auto;
  }
}

.dummy-header {
  height: 5rem;
  width: 100%;
}

.flew-grow-1 {
  flex-grow: 1;
}

.page-footer {
  min-width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4rem;
  position: absolute;
  bottom: 0;
}

.footer-link {
  font-weight: bold;
  font-size: 1rem;
  color: #383258;
}

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
}

.title-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  visibility: visible;
  opacity: 1;
}

.title-hidden {
  visibility: hidden;
  opacity: 0;
  max-height: 0px;
}

.spacer-small {
  transition: min-height 0.5s ease-in-out;
  min-height: 0px;
  display: flex;
  flex-direction: row;
  align-items: end;
  justify-content: center;
  visibility: visible;
}

.spacer-large {
  transition: min-height 2s ease-in-out;
  min-height: 20%;
}

.smooth-shrink {
  /* animation: grow-vertical; */
  animation-duration: 1.5s;
  animation-timing-function: ease-in;
  animation-name: shrink-vertical;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.pre-shrink {
  height: 55%;
}

.pulse {
  animation-duration: 1.5s;
  animation-timing-function: cubic-bezier();
  animation-name: pulse;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;
}

@keyframes shrink-vertical {
  from {
    height: 55%;
  }

  to {
    height: 0%;
  }
}

@keyframes pulse {
  from {
    opacity: 100%;
  }

  to {
    opacity: 20%;
  }
}

.scrollable-page {
  height: auto;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.page-content-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-content {
  max-width: 800px;
}
